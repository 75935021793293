import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/kszol/projects/noeadogatsby/src/components/layout/default-page-layout.tsx";
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Gallery from '../components/layout/gallery';
import Youtube from '../components/layout/youtube';
import thumbData from '../images/erreforditjuk/video/data.json';
import LightboxPageLayout from '../components/layout/lightbox-page-layout';
export const query = graphql`
  query {
    allFile(
      filter: {
        relativeDirectory: { eq: "erreforditjuk/2018" }
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
      }
    ) {
      nodes {
        base
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: FULL_WIDTH
            breakpoints: [210, 270, 370, 572, 720]
          )
          original {
            width
            height
          }
        }
      }
    }
    allJson(filter: { graphql: { eq: "EF2018" } }) {
      nodes {
        resources {
          index
          title
          image {
            base
          }
        }
      }
    }
    video: allFile(
      filter: {
        relativeDirectory: { eq: "erreforditjuk/video" }
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
      }
    ) {
      nodes {
        base
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: FULL_WIDTH
            breakpoints: [210, 270, 370, 572, 720]
          )
          original {
            width
            height
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = LightboxPageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1>{`Erre fordítjuk, amit adsz (2018)`}</h1>
    <h2>{`Noé állatotthon`}</h2>
    <p>{`Magyarország legnagyobb állatotthonaként 1992 óta végezzük a bajba jutott, megkínzott, balesetet szenvedett állatok mentését. Jelenleg 2 telephelyen közel 10 hektáron 1.200 kutya, cica, malac, kecske, juh, nyuszi, tengerimalac, csirke, kacsa, liba, páva, vaddisznó, mosómedve, ormányos medve, borz, ló, póni, szamár, láma, díszmadár, galamb, varjú, egerészölyv, teknős, hüllő és szarvasmarha és még sok másféle állatfaj átmeneti, vagy - sok esetben - végleges otthona.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(0, 3)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`2018-ban tovább folyt az állatotthon felelős működtetése, továbbra is biztos hátteret és ezzel teljes körű egészségügyi és állatjóléti ellátást tudunk biztosítani védenceinknek és az új bekerülőknek. Sajnos azt tapasztaljuk a mindennapokban, hogy egyre több a rászoruló állat, egész évben ömlöttek be a súlyosan sérült, vagy nagyon beteg állatok. És az olyan betegségek, mind például a szívférgesség gyakorlatilag napi szintű problémává vált, az állatokat is sújtó allergiákról nem is beszélve. Emiatt lényegesen megugrottak az amúgy is hatalmas állatorvosi, gyógyszer és gyógytáp költségeink. Ezért bizony egy újabb lyukkal beljebb húztuk azt a bizonyos nadrágszíjat és továbbra is nagyon körültekintő, felelős gazdálkodással működünk. De azért természetesen megmentett állat, megsegített kisebb menhely, szívesen látott gyerekcsoportokból, valamint fejlesztésekből 2018-ban is volt bőven. Az életmentések és NOÉs CSODÁK száma tovább nőtt!`}</p>
    <p>{`Egy vadonat új cicakifutót adtunk át, melynek a neve MacsKaLand lett. Itt elsősorban olyan cicák kapnak helyet, akik árván maradtak, vagy valamilyen tragédia miatt otthontalanná. Ez a kifutó úgy lett kialakítva, hogy a látogatók egész évben önállóan is látogathatják a csodaszép, simogatásra vágyó bársonytalpúakat. Felújítottuk a Kisgazdasági udvar aljzatát, megújultak a növényevők által használt etetők. Sár és csúszás-menetesítettük a lovak által használt alsó kapu környékét. Befejeződött az oktatóközpont vízelvezetése, körbe kaviccsal lett feltöltve.`}</p>
    <p>{`Az első legelőn elkészült a kehes lovak beállója, így minden időjárási körülmény között lent tudnak maradni a füves legelőn, ez nagyon fontos volt a betegségük megállítása miatt. Idősödő disznóinknak elkészült egy kényelmes és biztonságos elkerített udvar, istálló résszel és hatalmas kerttel. Ők azok, akik már a nagykondával nem tudják felvenni a versenyt az élelemért és a megfelelő alvóhelyért.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(3, 6)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Hátsó kórházrészlegünk - mely elsősorban a fajtamentések védenceinek ad helyet - belső villágítása megújult, végre tényleg látjuk mit csinálunk :) Illetve ugyanennek a részlegnek a körudvara több egymástól elszeparálható részre lett osztva és minden részre fűthető kutyaházak is lettek telepítve. Ez lehetővé teszi, hogy a betegeket biztonságosan mozgathassuk egész nap.`}</p>
    <p>{`Sikerült egy nekünk új állatorvosi autót és egy állatmentéshez használható autót is hadrendbe állítanunk. Utóbbit egy anonimitását kérő nagylelkű támogatónk egyszemélyi felajánlásából.`}</p>
    <p>{`Tovább zajlott az istálló tetejére telepített napkollektor rendszer üzembe helyezése és elindult egy nagy fejlesztés, a rehabilitációs kennelsor alapja elkészült.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(6, 9)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Nagyon sok minden megvalósult, ahogy sok-sok minden maradt erre az évre is, a teendők sosem fogynak el. Van néhány álmunk mely most már évek óta húzódik, nagyon reméljük, hogy ebben idén nagyot tudunk előre lépni. Elsődleges cél most, hogy a rehabilitációs kennelsor elkészüljön, nagyobb fejlesztéseket tervezünk még cicafronton is. Illetve évtizedes álmunk, a kölyök kutya karantént is beterveztük a 2019-es évre.`}</p>
    <p>{`2018-ban is folytatódott a NOÉ Állatotthon menhelysegítő programja. Célja hogy olyan nehezebb anyagi helyzetben lévő menhelyeknek nyújtsunk segítő kezet, ahol a szándék, a felelős gondolkozás, magatartás megvan, de a lehetősége az anyagi körülmények korlátozottak. Mit mondjunk nagyon nem kell keresgetni, az államilag egyáltalán nem finanszírozott honi állatvédelemben rengeteg segítség elkél. Programunkban elsősorban a kisebb vidéki menhelyeken élő kutyák oltásában, chipezésében és parazitamentesítésében tudtunk segítséget nyújtani. Illetve szukakutyák ivartalanításában, kiemelten az esetleg vemheseket. 2018-ban közel 20 alkalommal kaptak menhelyek élelmet, oltásokat, chipet. 2018-tól bekerült a programba a kombinált oltás is. Támogatott menhelyeink a teljesség igénye nélkül: Püspökladány, Törökszentmiklós, Kisvárda, Nyírmada, Ózd, Mezőkövesd, Békéscsaba, Orosháza az Ebremény. Terveink szerint 2019-ben tovább fejlődik a program és újabb elemmel bővül a támogatás, a menhelyek fertőtlenítéséhez kívánunk hozzájárulni. Hosszú évek óta nagyon jó kapcsolatban vagyunk a Hangyaközösséggel. Nekik köszönhetően, ahova idén az oltási - chipezési programmal nem jutottunk el, táp, fertőtlenítő, tisztítószer és egyéb adományainkat el tudtuk juttatni.`}</p>
    <p>{`Sok minden közepette mégis a fő dolog az állatok mentése. Idén is bőven 1.000 felett volt a bekerülő állatok száma. A többségük persze kutyus és cicus, de bőven akadtak egyéb teremtmények is. Rengeteg egészen apró, állandó gondoskodást igénylő. Konkrétan voltak olyan hónapjai az évnek, hogy minden szabad kézben cumi, fecskendő, csipesz volt, egy-egy apróság táplálásához.`}</p>
    <p>{`Volt sok szívszorító eset, amikor magunk sem hittük a látottakat. A bekerülő és a nálunk élő védenceinkről szóló hírek elolvashatóak ITT: `}<a href="http://www.noeallatotthon.hu/index.php?pid=2&tid=4" target="_blank" rel="noreferrer">{`Történetek Állatainkról`}</a></p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(9, 12)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Közel 900 állatot sikerült meggyógyítanunk, felnevelnünk és új családba juttatnunk, illetve repatriálnunk. A többiek a NOÉ Állatotthon végleges, vagy ideiglenes lakói lettek.
Sajnos idén extra sok védencünktől búcsút kellett vennünk. Nagyon sokan kerültek be már menthetetlen állapotban és az elöregedő állományunk miatt több régi, idős védencünket elvesztettük. Ezek a legborzasztóbb pillanatok, amit nem lehet megszokni, amit nem lehet feldolgozni, csak a többiek miatt tovább kell lépni ... de mindannyiuk a legkisebbtől a legnagyobbik, a legújabbtól a legrégebbiig bennünk élnek tovább ...`}</p>
    <p>{`2019`}{`.`}{` január 1-én az alapítvány védenceinek száma 1.121 volt.`}</p>
    <p>{`2018-ban tovább romlott a helyzet szívférgesség fronton, azt mondhatom, hogy 10 bekerülő kutyából átlag 1 biztosan pozitív szív vagy bőrféregre. A szűrés, diagnosztizálás és gyógykezelés elképesztően sokba kerülnek. Ez a betegség erősen negatív irányban befolyásolta az állatorvosi költségeinket. De jó hír a rosszban, hogy sikeresen vesszük fel a harcot és úgy tűnik, hogy megvannak a működő gyógyítási protokollok, így egyre nagyobb az esély a teljes gyógyulásra. Ki szívműtéttel, ki injekciós kezeléssel, mások lassabb gyógyszeres terápiával, de sikeresen gyógyulgatnak. Szerencsések vagyunk a legjobb szakorvosok állnak védenceink rendelkezésére, így több gyógyult és gyógyulófélben lévő kutyánk is van. A megelőzésben óriási nagy segítséget jelentett a CEVA támogatása, 2018-ban 2 x is nagy mennyiségű Vectra 3D spot-on-nal látta el kutyáinkat.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(12, 15)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`2017`}{`.`}{` január 1-től már Vas megyében is jelen vagyunk. Osztrák állatvédő alapítványtól vettük át a szergényi 100 férőhelyes kutyamenhely és a hozzá tartozó, európai színvonalú celldömölki karanténrészleg üzemeltetését. A menhelyen elsősorban a környék rosszul tartott kutyái és a közeli gyepmesteri telepek halálos ítélete elől mentett állatok élhetnek, hatalmas kennelekben, békében, boldogságban, biztonságban.`}</p>
    <p>{`Folyamatosan emberhiánnyal küzdünk, mind Budapesten, mind Vas megyében. Emiatt ott a celldömölki karantén részleget az év végére le kellett zárnunk és erősen vissza kellett szorítanunk a helyi mentéseket. Folyamatosan keressük a megoldást, reméljük, hogy hamarosan újra a régi rendben tud majd működni az állatok mentése. No persze ez nem jelenti azt, hogy cserben hagytuk a környék rászorulóit, a lehetőségekhez képest folytatjuk a mentéseket.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(15, 18)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Hogy ennyi állat megmenekülhetett, meggyógyulhatott, hogy ennyi álmunk valóra válhatott ezt nagy részben köszönhetjük azoknak a támogatóinknak, akik legutóbb is nekünk ajánlották fel személyi jövedelemadójuk 1%-át. Hálásan köszönjük Nektek, hogy ismét mellénk álltatok és a felajánlásotokkal újra biztos alapot teremtettek a a jövőbeli munkánk hoz.`}</p>
    <p>{`Sok minden megvalósult, sok dolog még álom. De érezve a támogatást, a bizalmat, nem dobjuk el az álmainkat a terveinket. Szépen sorban haladunk, tervezünk, számolunk. És talán egyszer ennek a listának is a végére érünk. De addig még egy-két apróságot megvalósítanánk. Ami a legfontosabb, hogy a megkezdett úton haladva szeretnénk biztosítani a védenceik biztos jövőjét, a kényelmüket, jólétüket, egészségügyi ellátásukat.`}</p>
    <p><strong parentName="p">{`Köszönjük mindenkinek, aki hozzájárult a munkánkhoz, önkéntesként, támogatóként, cégként vagy magánszemélyként, a személyi jövedelemadója 1%-nak felajánlásával, adománnyal, virtuális örökbefogadással, örökbefogadással, ideiglenes befogadással, az adomány vonal hívásával, vagy akár csak egy megosztással!`}</strong></p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(18, 21)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p><strong parentName="p">{`CSAK VELETEK SIKERÜLHET!`}</strong></p>
    <p><strong parentName="p">{`KÖSZÖNJÜK!`}</strong></p>
    <p>{`Hála Nektek, akik Noé Állatotthon Alapítvány védencei javára ajánlottátok fel a személyi jövedelemadótok 1 százalékát, újabb 1 évig biztosítva van az 1.200 védencünk - közülük évente mintegy 900 új bekerülő - ellátása, gyógyítása, gazdásítása vagy repatriálása. Keressük a megfelelő szavakat, hogy immár 28. éve álltok mellettünk, segítetek minket és a NOÉs állatokat. A segítségetekkel évente közel 1.000 életet tudunk megmenteni és számukra új esélyt adni az ÉLETRE!`}</p>
    <p>{`A NAV tájékoztatása szerint a 2018-as személyi jövedelemadók 1%-os felajánlásából, összesen 75 928 764 Ft összegben rendelkeztetek úgy, hogy biztos háttéret nyújtotok az Alapítvány gondozásában élő több mint 1.200 állatnak a következő egy évben.`}</p>
    <p>{`Óriási az összeg és ezzel hatalmas a felelősségünk. Továbbra is kizárólag az állatok segítésére, mentésére, ÉLETére fordítunk minden fillért! És, ahogy már nagyon sok éve, nem "csak" a NOÉs védencek részesülnek mindebből, de folytatjuk a kisebb menhelyek segítését, oltást, chipet, élelmet, ivartalanítást, parazitamnetesítést kap további rászorüló nagyjából 2.000 állat. És országosan vállajuk sérült, beteg, balesetes, életveszélyben lévő állatok gondozásunkba vételét.`}</p>
    <p>{`Edukációs tevékenységünk keretében mindenki számára elérhető, ingyenes oktatási programunk szeretettel várja óvodások, iskolások, csoportok látogatását és mi magunk is rendszeresen kijárunk tanintézményekbe előadást tartani a felelős állattartásról, a természet és az állatok tiszteletéről, szeretetéről.`}</p>
    <p>{`Bár ez az összeg egy biztos háttérországot jelent, nagyjából a fele annak, amennyi a biztonságos működésünkhöz, a mentésekhez, az állatok legmagasabb szintű ellátásához szükséges. A másik felét a következő egy évben is közösen kell előteremtenünk. Továbbra is reméljük, hogy velünk és a védenceinkkel maradtok, akiknek csak a közös segítségünk jelenti a túlélést.`}</p>
    <div className="w-full lg:w-auto grow flex flex-col mx-2 p-4 bg-white not-prose">
  <Youtube resources={thumbData.resources} nodes={props.data.video.nodes} mdxType="Youtube" />
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      